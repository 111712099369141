import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Box, Typography, Grid, Container } from "@mui/material";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import { Section } from "../Section";

import { StyledHandSurgeryAndMore } from "./HandSurgeryAndMore.styled";

const HandSurgeryAndMoreContent = ({ children }) => {
  return (
    <StyledHandSurgeryAndMore>
      <Section>
        <Container maxWidth="lg">
          <Box p={4}>
            <Typography variant="h4" color="secondary" align="center" mb={2}>
              Hand & Wrist
            </Typography>
            <Typography variant="h5" align="center" mb={2}>
              <i>
                "Complete Hand Surgery - Care from Reconstruction to
                Rejuvenation"
              </i>
            </Typography>
            <Typography paragraph>
              The hand and wrist are very complex; therefore, seeing a hand
              specialist can yield the best solution for conditions of the hand,
              wrist and arm. The Joyner Hand provides a number of surgical and
              non-surgical procedures for a variety of hand and arm conditions.
            </Typography>
            <Typography paragraph>
              A few of the services provided by TJH are as follows:
            </Typography>
            <Grid container>
              <Grid item xs={12} md={4}>
                <ul>
                  <li>Carpal tunnel</li>
                  <li>Arthritis</li>
                  <li>Wrist pain</li>
                  <li>Trigger finger</li>
                  <li>Ganglion cysts</li>
                </ul>
              </Grid>
              <Grid item xs={12} md={4}>
                <ul>
                  <li>Hand tumors</li>
                  <li>Scar removal and revision</li>
                  <li>Worker's compensation</li>
                  <li>Artificial joint replacements</li>
                  <li>Dupuytren's contracture</li>
                </ul>
              </Grid>

              <Grid item xs={12} md={4}>
                <ul>
                  <li>Nerve repair</li>
                  <li>Tendon injuries</li>
                  <li>Sports injuries</li>
                  <li>Fingertip injuries</li>
                  <li>Infections</li>
                </ul>
              </Grid>
              <Typography paragraph>
                See the patient education section for further conditions and
                procedures performed by The Joyner Hand.
              </Typography>
            </Grid>
          </Box>
        </Container>
      </Section>
      <Section secondary>
        <Container maxWidth="lg">
          <Box py={4}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  color="common.white"
                  align="center"
                  mb={2}
                >
                  <strong>Hand Care</strong>
                </Typography>
                <Typography
                  variant="h4"
                  color="common.white"
                  align="center"
                  mb={2}
                >
                  <i>“True Wellness With Healthy Hands"</i>
                </Typography>

                <Box display="flex" justifyContent="center">
                  <StaticImage
                    src="../../images/young-old-hand.jpg"
                    alt="young old hand"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Section>
    </StyledHandSurgeryAndMore>
  );
};

const HandSurgeryAndMore = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "hero-hand-surgery.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const pluginImage = getImage(placeholderImage);

  return (
    <>
      <BgImage image={pluginImage} style={{ minHeight: 375 }}>
        <Box
          sx={{
            height: 375,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignItems: `middle`,
          }}
        >
          <Box>
            <Typography variant="h3" align="center" color="common.white">
              <strong> Hand Surgery & More</strong>
            </Typography>
            {/* <Typography variant="h4" align="center" color="common.white">
              Boca Raton and East Delray Beach Hand Surgeon and More …
            </Typography> */}
          </Box>
        </Box>
      </BgImage>
      <HandSurgeryAndMoreContent />
    </>
  );
};

export default HandSurgeryAndMore;
