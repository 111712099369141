import * as React from "react";
import { Layout } from "../components/Layout";
import { HandSurgeryAndMore } from "../components/HandSurgeryAndMore";

export default function HandSurgeryAndMorePage() {
  return (
    <Layout>
      <HandSurgeryAndMore />
    </Layout>
  );
}
