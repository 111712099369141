
  import { styled } from '@mui/system';
  import { Box } from '@mui/material';

  const StyledHandSurgeryAndMore = styled(Box)(({theme})=>({
    padding: theme.spacing(0)
  }));

  export { StyledHandSurgeryAndMore };
  
      
      